export const APP_ROUTES = {
  DASHBOARD: {
    PATH: 'dashboard',
    CHILDREN: {
      CHILD: 'child'
    }
  },
  LOGIN: {
    PATH: 'login',
    CHILDREN: {}
  },
  SSO: {
    PATH: 'sso',
    CHILDREN: {}
  },
  SIGN_UP: {
    PATH: 'sign-up',
    CHILDREN: {}
  },
  ACCESS_CODE: {
    PATH: 'access-code',
    CHILDREN: {}
  },
  RESET_PASSWORD: {
    PATH: 'reset-password',
    CHILDREN: {}
  },
  FORGOT_PASSWORD: {
    PATH: 'forgot-password',
    CHILDREN: {}
  },
  MANAGE_USERS: {
    PATH: 'manage-users',
    CHILDREN: {}
  },
  ACTIVATION: {
    PATH: 'activation',
    CHILDREN: {}
  },
  SPLASH: {
    PATH: 'splash',
    CHILDREN: {}
  },
  TOS: {
    PATH: 'terms-of-service',
    CHILDREN: {}
  },
  PP: {
    PATH: 'privacy-policy',
    CHILDREN: {}
  },
  TOU: {
    PATH: 'terms-of-use',
    CHILDREN: {}
  },
  HOME: {
    PATH: 'home',
    CHILDREN: {}
  },
  POLICIES: {
    PATH: 'policies',
    CHILDREN: {
      DOCUMENT: {
        buildPath: (data: string) => APP_ROUTES.DASHBOARD.PATH + '/policy/document/' + data,
        PATH: 'policy/document/:documentId',
        CHILDREN: {}
      },
      QUESTIONNAIRE: {
        buildPath: (data: string) => APP_ROUTES.DASHBOARD.PATH + '/policy/questionnaire/' + data,
        PATH: 'policy/questionnaire/:documentId',
        CHILDREN: {}
      }
    }
  },
  SURVEYS: {
    PATH: 'surveys',
    CHILDREN: {
      DOCUMENT: {
        buildPath: (data: string) => APP_ROUTES.DASHBOARD.PATH + '/surveys/document/' + data,
        PATH: 'surveys/document/:documentId',
        CHILDREN: {}
      }
      // QUESTIONNAIRE: {
      //   buildPath: (data: string) => APP_ROUTES.DASHBOARD.PATH + '/policy/questionnaire/' + data,
      //   PATH: 'policy/questionnaire/:documentId',
      //   CHILDREN: {}
      // }
    }
  },
  PORTFOLIOS: {
    PATH: 'portfolios',
    CHILDREN: {
      // DOCUMENT: {
      //   buildPath: (data: string) => APP_ROUTES.DASHBOARD.PATH + '/policy/document/' + data,
      //   PATH: 'policy/document/:documentId',
      //   CHILDREN: {}
      // },
      // QUESTIONNAIRE: {
      //   buildPath: (data: string) => APP_ROUTES.DASHBOARD.PATH + '/policy/questionnaire/' + data,
      //   PATH: 'policy/questionnaire/:documentId',
      //   CHILDREN: {}
      // }
    }
  },
  SETTINGS: {
    PATH: 'settings',
    CHILDREN: {}
  },
  CONTROLS: {
    PATH: 'controls',
    CHILDREN: {
      DOCUMENT: {
        buildPath: (data: number) => APP_ROUTES.DASHBOARD.PATH + '/controls/document/' + data,
        PATH: 'controls/document/:documentId',
        CHILDREN: {}
      }
    }
  },
  PROFILE: {
    PATH: 'profile',
    CHILDREN: {}
  },
  HELP_FEEDBACK: {
    PATH: 'help-feedback',
    CHILDREN: {}
  },
  PERMISSIONS: {
    PATH: 'permissions',
    CHILDREN: {}
  }
};

export type AppRouteKeys = keyof typeof APP_ROUTES;
export type AppRoute = (typeof APP_ROUTES)[AppRouteKeys];
